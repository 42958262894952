import React from 'react';
import './Title.css';
import { ShakingWrapper } from '../ShakingWrapper/ShakingWrapper';

export interface TitleProps {
    firstText: string;
    firstTextSize: number;
    firstTextColor: string;
    secondText: string;
    secondTextSize: number;
    secondTextColor: string;
    animate: boolean;
}

export const TitleComponent: React.FC<TitleProps> = ({
                                                         firstText,
                                                         firstTextSize,
                                                         firstTextColor,
                                                         secondText,
                                                         secondTextSize,
                                                         secondTextColor,
                                                         animate,
                                                     }: TitleProps) => {
    const firstTextElement = (
        <p className="title-text first-text" style={{ fontSize: firstTextSize, color: firstTextColor }}>
            {firstText}
        </p>
    );

    const secondTextElement = (
        <p className="title-text second-text" style={{ fontSize: secondTextSize, color: secondTextColor }}>
            {secondText}
        </p>
    );

    return (
        <div>
            {animate ? (
                <>
                    <ShakingWrapper>{firstTextElement}</ShakingWrapper>
                    <ShakingWrapper>{secondTextElement}</ShakingWrapper>
                </>
            ) : (
                <>
                    {firstTextElement}
                    {secondTextElement}
                </>
            )}
        </div>
    );
};
