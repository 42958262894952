// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tweet-content {
    max-height: 2400px;
    transform: scale(0.1);
    padding: 80px;
    background-color: rgba(18, 56, 94, 0.8);
    border-radius: 9%;
    /* Add any additional styles for .tweet-card as needed */
}

.twitter-logo {
    position: absolute;
    top: 44.8%;
    right: 44.5%;
    transform: scale(0.5);
    width: 50px;
    height: auto;
    z-index: 1;
}

.tweet-card {
    position: relative;
}

.tweet-media {
    padding-top: 50%; /* 16:9 aspect ratio for media */
}
`, "",{"version":3,"sources":["webpack://./src/components/Tweet/Tweet.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,aAAa;IACb,uCAAuC;IACvC,iBAAiB;IACjB,wDAAwD;AAC5D;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB,EAAE,gCAAgC;AACtD","sourcesContent":[".tweet-content {\r\n    max-height: 2400px;\r\n    transform: scale(0.1);\r\n    padding: 80px;\r\n    background-color: rgba(18, 56, 94, 0.8);\r\n    border-radius: 9%;\r\n    /* Add any additional styles for .tweet-card as needed */\r\n}\r\n\r\n.twitter-logo {\r\n    position: absolute;\r\n    top: 44.8%;\r\n    right: 44.5%;\r\n    transform: scale(0.5);\r\n    width: 50px;\r\n    height: auto;\r\n    z-index: 1;\r\n}\r\n\r\n.tweet-card {\r\n    position: relative;\r\n}\r\n\r\n.tweet-media {\r\n    padding-top: 50%; /* 16:9 aspect ratio for media */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
