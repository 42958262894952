import React, { useEffect, useState, ReactElement } from 'react';
import { createComponentFromData } from "../../services/componentFactory";
import { exampleTitles } from "../../services/seeder";
import './Home.css';
import { TweetContainer } from '../../components';
import { StaticComponentsHeights } from "../../constants/HomeConstants";
import CXCButton from "../../assets/CXCButton/CXC_Map.png"
import {PortfolioButton} from "../../components/PortfolioButton/PortfolioButton";

function Home({ setHomeButtonVisible, setBackButtonVisible }) {
    useEffect(() => {
        setHomeButtonVisible(false);
        setBackButtonVisible(false);
    }, [setBackButtonVisible, setHomeButtonVisible]);

    const [leftTweets, setLeftTweets] = useState([]);
    const [rightTweets, setRightTweets] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // TODO: Receive components data from backoffice when implemented
    const jsonData = { components: exampleTitles } /* Mock of JSON data received from the backend */;
    const components: (ReactElement | null)[] = jsonData.components.map((data: any) => createComponentFromData(data));
    const positions = jsonData.components.map((data: any) => data.position);

    useEffect(() => {
        // Fetch tweets from the API
        fetch('https://cisco-tweets.natasquad.com/tweets')
            .then((response) => response.json())
            .then((data) => {
                const first30Tweets = data.slice(0, 30);

                setLeftTweets(first30Tweets.slice(0, 15));
                setRightTweets(first30Tweets.slice(15, 30));
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching tweets:', error);
                setIsLoading(false);
            });
    }, []);

    return (
        <div>
            <div className="component-container">
                {isLoading ? (
                    <p>Loading tweets...</p>
                ) : (
                    <>
                        {/* Render first 5 tweets inside a TweetContainer */}
                        {leftTweets && <TweetContainer index={0} tweets={leftTweets}/>}
                        {/* Render actual components */}
                        {components.map((Component, index) => (
                            <div key={index} style={{
                                transform: `translateY(${StaticComponentsHeights[positions[index]]}px)`
                            }}>{Component}</div>
                        ))}
                        {/* Render last 5 tweets inside another TweetContainer */}
                        {rightTweets && <TweetContainer index={1} tweets={rightTweets} />}
                    </>
                )}
            </div>
            <div className="cxc-button">
                <PortfolioButton text={"CXC Map"} route={"/home"}/>
            </div>

        </div>
    );
}

export default Home;
