import React, { useEffect, useRef } from 'react';
// @ts-ignore
import anime from 'animejs';

// @ts-ignore
export const ShakingWrapper = ({ children }) => {
    const wrapperRef = useRef(null);

    useEffect(() => {
        const wrapper = wrapperRef.current;
        if (!wrapper) return;

        const interval = setInterval(() => {
            const randomX = Math.random() * 10 - 5;
            const randomY = Math.random() * 10 - 5;
            const randomDuration = Math.floor(Math.random() * 800) + 1500;
            anime({
                targets: wrapper,
                translateX: randomX,
                translateY: randomY,
                duration: randomDuration,
                easing: 'easeInOutSine',
            });
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []); // Empty dependency array ensures the effect runs once after the initial render

    return <div ref={wrapperRef}>{children}</div>;
};
