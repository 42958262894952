// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tweet-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 20%;
}

.center-tweet {
    position: absolute;
    z-index: 2;
    width: 100%;
    transform: scale(0.8);
 }

.surrounding-tweets {
    position: absolute;
    width: 100%;
    z-index: 1;
    transform: scale(0.5);
}
`, "",{"version":3,"sources":["webpack://./src/components/TweetContainer/TweetContainer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,qBAAqB;CACxB;;AAED;IACI,kBAAkB;IAClB,WAAW;IACX,UAAU;IACV,qBAAqB;AACzB","sourcesContent":[".tweet-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100vh;\r\n    width: 20%;\r\n}\r\n\r\n.center-tweet {\r\n    position: absolute;\r\n    z-index: 2;\r\n    width: 100%;\r\n    transform: scale(0.8);\r\n }\r\n\r\n.surrounding-tweets {\r\n    position: absolute;\r\n    width: 100%;\r\n    z-index: 1;\r\n    transform: scale(0.5);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
