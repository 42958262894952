import React from "react";
import { TweetComponent, TweetProps } from "../Tweet/Tweet";
import "./TweetContainer.css";
import {StaticPositionsSets, TitlesSets, TitleStaticPositionsSet} from "../../constants/TweetContainerConstants";
import { AnimatedWrapper } from "../AnimatedWrapper/AnimatedWrapper";
import {TitleComponent} from "../Title/Title";
import {DisappearingWrapper} from "../DisappearingWrapper/DisappearingWrapper";

interface TweetContainerProps {
    index: number;
    tweets: TweetProps[];
}

export const TweetContainer: React.FC<TweetContainerProps> = ({ index, tweets }: TweetContainerProps) => {
    // TODO: Add a parameter for setting which positionsSet to use
    let staticPositions = StaticPositionsSets[index];
    let titles = TitlesSets[index];
    let titlesPositions = TitleStaticPositionsSet[index];

    const mainTweets = [tweets[0], tweets[5], tweets[10]];

    return (
        <div className="tweet-container">
            <div className="center-tweet">
                <AnimatedWrapper>
                    <TweetComponent
                        id={mainTweets[1].id}
                        created_at={mainTweets[1].created_at}
                        media={mainTweets[1].media}
                        text={mainTweets[1].text}
                    />
                    <TweetComponent
                        id={mainTweets[2].id}
                        created_at={mainTweets[2].created_at}
                        media={mainTweets[2].media}
                        text={mainTweets[2].text}
                    />
                    <TweetComponent
                        id={mainTweets[0].id}
                        created_at={mainTweets[0].created_at}
                        media={mainTweets[0].media}
                        text={mainTweets[0].text}
                    />
                </AnimatedWrapper>
            </div>
            <div className="surrounding-tweets">
                {tweets.slice(1, 5).map((tweet, index) => {
                    const position = staticPositions[index];
                    // Tweets for rotate
                    const secondTweet = tweets[index + 5];
                    const thirdTweet = tweets[index + 10];
                    return (
                        <div key={tweet.id} className={`tweet-${index + 1}`} style={{ position: "absolute", left: `${position.x}px`, top: `${position.y}px` }}>
                            <AnimatedWrapper>
                                <TweetComponent
                                    id={tweet.id}
                                    created_at={tweet.created_at}
                                    media={tweet.media}
                                    text={tweet.text}
                                />
                                <TweetComponent
                                    id={secondTweet.id}
                                    created_at={secondTweet.created_at}
                                    media={secondTweet.media}
                                    text={secondTweet.text}
                                />
                                <TweetComponent
                                    id={thirdTweet.id}
                                    created_at={thirdTweet.created_at}
                                    media={thirdTweet.media}
                                    text={thirdTweet.text}
                                />
                            </AnimatedWrapper>
                        </div>
                    );
                })}
            </div>
            <div>
                {titles.map((title, index) => {
                    const position = titlesPositions[index];
                    return (
                        <div style={{ position: "relative", left: `${position.x}px`, top: `${position.y}px` }}>
                            <DisappearingWrapper>
                                <TitleComponent {...title}></TitleComponent>
                            </DisappearingWrapper>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
