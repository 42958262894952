// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-text {
    font-weight: bold;
    font-family: "CiscoSansTT", sans-serif;
}

.first-text {
    margin-bottom: 2px; /* Adjust the margin to reduce spacing */
}

.second-text {
    margin-top: 2px; /* Adjust the margin to reduce spacing */
}
`, "",{"version":3,"sources":["webpack://./src/components/Title/Title.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,sCAAsC;AAC1C;;AAEA;IACI,kBAAkB,EAAE,wCAAwC;AAChE;;AAEA;IACI,eAAe,EAAE,wCAAwC;AAC7D","sourcesContent":[".title-text {\r\n    font-weight: bold;\r\n    font-family: \"CiscoSansTT\", sans-serif;\r\n}\r\n\r\n.first-text {\r\n    margin-bottom: 2px; /* Adjust the margin to reduce spacing */\r\n}\r\n\r\n.second-text {\r\n    margin-top: 2px; /* Adjust the margin to reduce spacing */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
