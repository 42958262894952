import React, { useState } from 'react';
import './App.css';
import Home from "./pages/Home/Home";
import background from "./assets/background.mp4";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import BackIcon from "@mui/icons-material/ArrowBackIosOutlined";

function App() {
  const [isHomeButtonVisible, setHomeButtonVisible] = useState(false);
  const [isBackButtonVisible, setBackButtonVisible] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="App">
        {/* Background video */}
        <video className="video-background" autoPlay loop muted>
            <source src={background} type="video/mp4" />
        </video>

        <div className="buttons">
            {/* Home Button */}
            {isHomeButtonVisible &&
                <div className="home_button">
                    <Button
                        onClick={async () => {
                            navigate("/home");
                        }}
                    >
                        <HomeIcon className="home_icon" />
                    </Button>
                </div>
            }

            {/* Back Button */}
            {isBackButtonVisible &&
                <div className="back_button">
                    <Button
                        onClick={async () => {
                            navigate(-1);
                        }}
                    >
                        <BackIcon className="back_icon" />
                    </Button>
                </div>
            }
        </div>

        {/* Page Routing */}
        <div className="content">
            <Routes>
                <Route path="/home" element={<Home setHomeButtonVisible={setHomeButtonVisible} setBackButtonVisible={setBackButtonVisible}/>} />
                <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
        </div>
    </div>
  );
}

export default App;
