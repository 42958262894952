const exampleTitle1 = {
    type: "Title",
    index: 0,
    position: 1,
    firstText: 'Brand, Solutions, News...',
    firstTextSize: 24,
    firstTextColor: 'Red',
    secondText: 'All you need to know about Cisco!',
    secondTextSize: 18,
    secondTextColor: 'White',
    animate: true
};

const exampleTitle2 = {
    type: "Title",
    index: 1,
    position: 2,
    firstText: 'Social Media, Deals, Paris 2024...',
    firstTextSize: 20,
    firstTextColor: '#FBAB2C',
    secondText: 'Stay tuned, with Cisco!',
    secondTextSize: 22,
    secondTextColor: 'White',
    animate: true
};

export const exampleTitles = [exampleTitle1, exampleTitle2];
