// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.decorated-title {
    position: relative;
}

.empty-square-0 {
    position: absolute;
    top: -90px;
    left: -25px;
    width: 120px;
    height: 120px;
}

.empty-square-1 {
    position: absolute;
    rotate: 90deg;
    top: -50px;
    left: 220px;
    width: 120px;
    height: 120px;
}

.filled-square-0 {
    position: absolute;
    top: -50px;
    left: 80px;
    width: 50px;
    height: 50px;
}

.filled-square-1 {
    position: absolute;
    top: 60px;
    left: 150px;
    width: 50px;
    height: 30px;
}

.small-square-0 {
    position: absolute;
    top: -35px;
    left: 25px;
    width: 20px;
    height: 20px;
}

.small-square-1 {
    position: absolute;
    top: -30px;
    left: 270px;
    width: 20px;
    height: 20px;
}

.additional-small-square {
    position: absolute;
    top: -50px;
    left: -80px;
    width: 20px;
    height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/DecoratedTitle/DecoratedTitle.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,UAAU;IACV,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,WAAW;IACX,YAAY;AAChB","sourcesContent":[".decorated-title {\r\n    position: relative;\r\n}\r\n\r\n.empty-square-0 {\r\n    position: absolute;\r\n    top: -90px;\r\n    left: -25px;\r\n    width: 120px;\r\n    height: 120px;\r\n}\r\n\r\n.empty-square-1 {\r\n    position: absolute;\r\n    rotate: 90deg;\r\n    top: -50px;\r\n    left: 220px;\r\n    width: 120px;\r\n    height: 120px;\r\n}\r\n\r\n.filled-square-0 {\r\n    position: absolute;\r\n    top: -50px;\r\n    left: 80px;\r\n    width: 50px;\r\n    height: 50px;\r\n}\r\n\r\n.filled-square-1 {\r\n    position: absolute;\r\n    top: 60px;\r\n    left: 150px;\r\n    width: 50px;\r\n    height: 30px;\r\n}\r\n\r\n.small-square-0 {\r\n    position: absolute;\r\n    top: -35px;\r\n    left: 25px;\r\n    width: 20px;\r\n    height: 20px;\r\n}\r\n\r\n.small-square-1 {\r\n    position: absolute;\r\n    top: -30px;\r\n    left: 270px;\r\n    width: 20px;\r\n    height: 20px;\r\n}\r\n\r\n.additional-small-square {\r\n    position: absolute;\r\n    top: -50px;\r\n    left: -80px;\r\n    width: 20px;\r\n    height: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
