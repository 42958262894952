import React, { FC } from "react";
import TweetCard from "react-tweet-card";
import './Tweet.css';
import { ShakingWrapper } from "../ShakingWrapper/ShakingWrapper";
import CiscoLogo from '../../assets/logo_cisco.jpg';
import TwitterLogo from '../../assets/twitter_logo.webp'; // Import the Twitter logo

interface Media {
    type: string;
    url: string;
}

export interface TweetProps {
    id: number;
    text: string;
    created_at: string;
    media: Media[];
}

export const TweetComponent: FC<TweetProps> = ({ id, text, created_at, media }: TweetProps) => {
    const tweetMedia = media
        .map(item => ({ src: item.url, isVideoThumbnail: item.type === 'video' }));

    return (
        <div>
            {/* TODO: Implement configurable shaking to tweets */}
            <ShakingWrapper>
                <div className="tweet-card"> {/* Add a div for the .tweet-card container */}
                    <img className="twitter-logo" src={TwitterLogo} alt="Twitter Logo" /> {/* Twitter logo inside .tweet-card */}
                    {tweetMedia.length !== 0 ?
                        <TweetCard
                            className="tweet-content"
                            author={{
                                name: 'Cisco',
                                username: 'Cisco',
                                isVerified: true,
                                image: CiscoLogo
                            }}
                            tweet={text}
                            tweetImages={tweetMedia}
                            time={new Date(created_at)}
                            fitInsideContainer
                        />
                        :
                        <TweetCard
                            className="tweet-content"
                            author={{
                                name: 'Cisco',
                                username: 'Cisco',
                                isVerified: true,
                                image: CiscoLogo
                            }}
                            tweet={text}
                            time={new Date(created_at)}
                            fitInsideContainer
                        />
                    }
                </div>
            </ShakingWrapper>
        </div>
    );
};
