// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.component-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    height: 90%;
    padding: 20px;
    position: absolute;
}

.cxc-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    height: 8%;
    width: auto;
    z-index: 3;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,UAAU;IACV,WAAW;IACX,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,UAAU;IACV,WAAW;IACX,UAAU;AACd","sourcesContent":[".component-container {\r\n    display: flex;\r\n    justify-content: space-around;\r\n    align-items: center;\r\n    width: 90%;\r\n    height: 90%;\r\n    padding: 20px;\r\n    position: absolute;\r\n}\r\n\r\n.cxc-button {\r\n    position: absolute;\r\n    bottom: 20px;\r\n    right: 20px;\r\n    height: 8%;\r\n    width: auto;\r\n    z-index: 3;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
