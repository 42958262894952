import React, { useEffect, useState, useRef, Children, cloneElement  } from 'react';
import {AnimationLoopSeconds} from "../../constants/AnimationConstants";

// @ts-ignore
export const AnimatedWrapper = ({ children }) => {
    const [isVisible, setIsVisible] = useState(true);
    const [visibleIndex, setVisibleIndex] = useState(0);
    const childrenArray = React.Children.toArray(children);
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;

        if (!isVisible) {
            // Disappear to the top
            container.style.transition = 'transform 2.2s ease';
            container.style.transform = 'translateY(-70%)';

            // Teleport to bottom
            setTimeout(() => {
                container.style.transition = 'none';
                container.style.transform = 'translateY(40%)';
                const nextIndex = (visibleIndex + 1) % childrenArray.length;
                setVisibleIndex(nextIndex);
            }, 600);

            // Appear from the bottom
            setTimeout(() => {
                container.style.transition = 'transform 1.5s ease';
                container.style.transform = 'translateY(0%)';
            }, 650);
        }

        return () => {
            container.style.transition = 'none';
        };
    }, [isVisible]);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsVisible((prev) => !prev);
        }, AnimationLoopSeconds * 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div ref={containerRef}>
            {Children.map(childrenArray[visibleIndex], (child) =>
                cloneElement(child, {
                    // You can pass any additional props here if needed
                })
            )}
        </div>
    );
};
