// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolio-button {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 20px;
    background-color: white;
    color: #FBAB2C;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.portfolio-button:hover {
    background-color: #FBAB2C;
    color: white;
}

.button-text {
    margin-right: 10px;
    margin-left: 5px;
    font-weight: bold;
    font-size: 25px;
    white-space: nowrap;
}

.button-icon {
    color: black;
    width: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PortfolioButton/PortfolioButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;IACd,uDAAuD;AAC3D;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,WAAW;AACf","sourcesContent":[".portfolio-button {\r\n    display: flex;\r\n    align-items: center;\r\n    text-decoration: none;\r\n    padding: 5px 10px;\r\n    border-radius: 20px;\r\n    background-color: white;\r\n    color: #FBAB2C;\r\n    transition: background-color 0.3s ease, color 0.3s ease;\r\n}\r\n\r\n.portfolio-button:hover {\r\n    background-color: #FBAB2C;\r\n    color: white;\r\n}\r\n\r\n.button-text {\r\n    margin-right: 10px;\r\n    margin-left: 5px;\r\n    font-weight: bold;\r\n    font-size: 25px;\r\n    white-space: nowrap;\r\n}\r\n\r\n.button-icon {\r\n    color: black;\r\n    width: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
