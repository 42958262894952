import {TitleProps} from "../components/Title/Title";

const StaticPositionsSet1 = [
    { x: -500, y: -1320 },
    { x: -500, y: -980 },
    { x: 400, y: -1300 },
    { x: 400, y: -1000 }
];

const StaticPositionsSet2 = [
    { x: -500, y: -1320 },
    { x: -500, y: -980 },
    { x: 400, y: -1250 },
    { x: 400, y: -1000 }
];

export const StaticPositionsSets = [StaticPositionsSet1, StaticPositionsSet2];

const TitlesSet1: TitleProps[] = [
    {
        firstText: "Hybrid Work",
        firstTextColor: "White",
        firstTextSize: 20,
        secondText: "",
        secondTextColor: "White",
        secondTextSize: 20,
        animate: true
    },
    {
        firstText: "Business",
        firstTextColor: "#FBAB2C",
        firstTextSize: 20,
        secondText: "",
        secondTextColor: "White",
        secondTextSize: 20,
        animate: true
    },
    {
        firstText: "Security",
        firstTextColor: "Green",
        firstTextSize: 20,
        secondText: "",
        secondTextColor: "White",
        secondTextSize: 20,
        animate: true
    }
];

const TitlesSet2: TitleProps[] = [
    {
        firstText: "Education",
        firstTextColor: "Green",
        firstTextSize: 20,
        secondText: "",
        secondTextColor: "White",
        secondTextSize: 20,
        animate: true
    },
    {
        firstText: "Olympic Games",
        firstTextColor: "Red",
        firstTextSize: 20,
        secondText: "",
        secondTextColor: "White",
        secondTextSize: 20,
        animate: true
    },
    {
        firstText: "Social Media",
        firstTextColor: "#FBAB2C",
        firstTextSize: 20,
        secondText: "",
        secondTextColor: "White",
        secondTextSize: 20,
        animate: true
    }
];

export const TitlesSets = [TitlesSet1, TitlesSet2];

const TitlesStaticPositions1 = [
    { x: 0, y: -90 },
    { x: -130, y: 15 },
    { x: 100, y: 120 },
];

const TitlesStaticPositions2 = [
    { x: 100, y: -110 },
    { x: -160, y: 10 },
    { x: 0, y: 80 },
];

export const TitleStaticPositionsSet = [TitlesStaticPositions1, TitlesStaticPositions2];
