import {ReactElement} from "react";
import {TweetComponent, DecoratedTitle} from "../components";
export function createComponentFromData(data: any): ReactElement | null {
    switch (data.type) {
        case 'Title':
            return <DecoratedTitle index={data.index} titleProps={data} />;
        case 'Tweet':
            return TweetComponent(data);
        // TODO: Implement creation logic for other component types here.
        default:
            return null; // Return null for unknown types.
    }
}
