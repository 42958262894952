import React from 'react';
import { Link } from 'react-router-dom';
import './PortfolioButton.css';
import PortfolioIcon from "../../assets/PortfolioButton/arrow.svg";

interface PortfolioButtonProps {
    text: string;
    route: string;
}

export const PortfolioButton: React.FC<PortfolioButtonProps> = ({ text, route }: PortfolioButtonProps) => {
    return (
        <Link to={route} className="portfolio-button">
            <span className="button-text">{text}</span>
            <img src={PortfolioIcon} alt={"Portfolio Icon"} className="button-icon" />
        </Link>
    );
};
