import React, { useEffect, useState, useRef } from 'react';
import {AnimationLoopSeconds} from "../../constants/AnimationConstants";

// @ts-ignore
export const DisappearingWrapper = ({ children }) => {
    const [isVisible, setIsVisible] = useState(true);
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;

        if (!isVisible) {
            container.style.opacity = '0';
            container.style.transition = 'opacity 0.5s ease';

            setTimeout(() => {
                container.style.opacity = '1';
                container.style.transition = 'opacity 2s ease';
            }, 1300);
        }

        return () => {
            container.style.transition = 'none';
        };
    }, [isVisible]);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsVisible((prev) => !prev);
        }, AnimationLoopSeconds * 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div ref={containerRef}>
            {children}
        </div>
    );
};
