// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.buttons {
  position: absolute;
  top: 50%;
  left: 10%;
  z-index: 3;
}

.home_button {
  transform: scale(1.3);
  padding-bottom: 5px;
}

.home_icon {
  color: white;
}

.back_button {
  transform: scale(1.3);
  padding-top: 5px;
}

.back_icon {
  color: white;
}

.content {
  position: absolute;
  top: 0;
  left: 15%;
  width: 85%;
  height: 100vh;
}

iframe#webpack-dev-server-client-overlay{display:none!important}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,UAAU;EACV,aAAa;AACf;;AAEA,yCAAyC,sBAAsB","sourcesContent":[".App {\r\n  text-align: center;\r\n}\r\n\r\n.video-background {\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  object-fit: cover;\r\n  z-index: -1;\r\n}\r\n\r\n.buttons {\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 10%;\r\n  z-index: 3;\r\n}\r\n\r\n.home_button {\r\n  transform: scale(1.3);\r\n  padding-bottom: 5px;\r\n}\r\n\r\n.home_icon {\r\n  color: white;\r\n}\r\n\r\n.back_button {\r\n  transform: scale(1.3);\r\n  padding-top: 5px;\r\n}\r\n\r\n.back_icon {\r\n  color: white;\r\n}\r\n\r\n.content {\r\n  position: absolute;\r\n  top: 0;\r\n  left: 15%;\r\n  width: 85%;\r\n  height: 100vh;\r\n}\r\n\r\niframe#webpack-dev-server-client-overlay{display:none!important}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
