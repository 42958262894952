import React from 'react';
import { TitleComponent, TitleProps } from '../Title/Title';
import emptySquare from "../../assets/title/square.webp";
import filledSquare from "../../assets/title/square2.webp";
import smallSquare from "../../assets/title/littlesquare.webp";
import "./DecoratedTitle.css";

interface DecoratedTitleProps {
    index: number;
    titleProps: TitleProps;
}

export const DecoratedTitle: React.FC<DecoratedTitleProps> = ({ index, titleProps }: DecoratedTitleProps) => {
    return (
        <div className="decorated-title">
            <TitleComponent {...titleProps} />

            <img src={emptySquare} alt={`Empty Square ${index}`} className={`empty-square-${index}`} />
            <img src={smallSquare} alt={`Small Square ${index}`} className={`small-square-${index}`} />
            <img src={filledSquare} alt={`Filled Square ${index}`} className={`filled-square-${index}`} />
            {index === 1 && <img src={smallSquare} alt={`Additional Square ${index}`} className="additional-small-square" />}
        </div>
    );
};
